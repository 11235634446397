import React from "react";

const Footer = () => {
  return (
    <footer>
      <center>
        <h2>Savlatbek Abdullayev | &copy; 2023</h2>
      </center>
    </footer>
  );
};

export default Footer;
